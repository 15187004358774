<template>
    <div> 
        <b-col v-if="this.totalCreditsSold.length > 0">
            <b-table             
                ref="selectableTable"
                :fields="fieldsViewTotalCreditsSold" 
                :items="totalCreditsSold"                             
                responsive                
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected">

                    <template #cell(saleDate)="data">
                        {{ (data.item.saleDate.slice(0, 10)) }}
                    </template>

                    <template #cell(amountCredits)="data">
                        {{ (data.item.amountCredits) }}
                    </template>

                    <template #cell(valuePerCredit)="data">
                        $ {{parseFloat(data.item.valuePerCredit).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                    </template>

                    <template #cell(certificationCost)="data">
                        $ {{parseFloat(data.item.certificationCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                    </template>

                    <template #cell(auditingCost)="data">
                        $ {{parseFloat(data.item.auditingCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                    </template>

                    <template #cell(transactionCost)="data">
                        $ {{parseFloat(data.item.transactionCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                    </template>

                    <template #cell(managementCost)="data">
                        $ {{parseFloat(data.item.managementCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                    </template>

                    <template #cell(monitoringCost)="data">
                        $ {{parseFloat(data.item.monitoringCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                    </template>
            </b-table>
        </b-col>

        <b-col v-else>
            <p class="mt-2">Esta finca aún no tiene créditos vendidos...</p>
        </b-col>
    </div>
</template>

<script>

import axios from '@axios'
import {
    BTable, BCol 
} from 'bootstrap-vue'

export default {
  name:"ViewTotalCreditsSoldComponent",
  props:{
    carbonFarmID: null
  },
  components: { 
    BTable, 
    BCol
  },
  data(){
    return{
        selectMode: 'single',     
        fieldsViewTotalCreditsSold: [
                { key: 'saleDate', label: 'Fecha Venta' },
                { key: 'amountCredits', label: 'Cantidad de Créditos' },
                { key: 'valuePerCredit', label: 'Valor por crédito' },
                { key: 'certificationCost', label: 'Costo Certificación' },
                { key: 'auditingCost', label: 'Costo Auditoría' },
                { key: 'transactionCost', label: 'Costo Transaccional' },
                { key: 'managementCost', label: 'Costo Administrativo' },
                { key: 'monitoringCost', label: 'Costo Monitoreo' },
            ],
        saleDate: null,
        amountCredits: null,
        valuePerCredit: null,
        certificationCost: null,
        auditingCost: null,
        transactionCost: null,
        managementCost: null,
        monitoringCost: null,
        totalCreditsSold: [{}],
        }
    },
    created(){
        this.GetViewTotalCreditsSoldByCarbonFarmID();
    },

    methods:{
        GetViewTotalCreditsSoldByCarbonFarmID(){
            axios.get('/CarbonSale/GetViewTotalCreditsSoldByCarbonFarmID',  { params: { carbonFarmID:this.carbonFarmID}})
            .then (response =>{
                this.totalCreditsSold = response.data;
                console.log(this.totalCreditsSold)              
                if(this.totalCreditsSold.length > 0){
                    this.totalCreditsSold.forEach(function(item, index){
                        this.farmName = item.farmName;
                        this.productorName = item.productorName;
                        this.saleDate = item.saleDate.substring(0,10);
                        this.amountCredits = item.amountCredits;
                        this.valuePerCredit = item.valuePerCredit;
                        this.certificationCost = item.certificationCost;
                        this.auditingCost = item.auditingCost;
                        this.transactionCost = item.transactionCost;
                        this.managementCost = item.managementCost;
                        this.monitoringCost = item.monitoringCost
                    });
                }
            })                     
        },
        onRowSelected(items) {
            this.userSelected = items[0];  
        }
  }
}

</script>

<style>
.table td{
    padding-right: 0ch;
}
</style>