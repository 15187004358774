<template>
    <div> 
        <b-col v-if="this.totalGeneratedCredits.length > 0">
            <b-table             
                ref="selectableTable"
                :fields="fieldsViewTotalGeneratedCredits" 
                :items="totalGeneratedCredits"                             
                responsive                
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected">

                    <template #cell(fromDate)="data">
                        {{ (data.item.fromDate.slice(0, 10)) }}
                    </template>

                    <template #cell(toDate)="data">
                        {{ (data.item.toDate.slice(0, 10)) }}
                    </template>

                    <template #cell(emissions)="data">
                        {{ (data.item.emissions) }}
                    </template>

                    <template #cell(captures)="data">
                        {{ (data.item.captures) }}
                    </template>

                    <template #cell(net)="data">
                        {{ (data.item.net) }}
                    </template>

                    <template #cell(generatedCredits)="data">
                        {{ (data.item.generatedCredits) }}
                    </template>
            </b-table>
        </b-col>

        <b-col v-else>
            <p class="mt-2">Esta finca aún no tiene créditos generados...</p>
        </b-col>
    </div>
</template>

<script>

import axios from '@axios'
import {
    BTable, BCol 
} from 'bootstrap-vue'

export default {
  name:"ViewTotalGeneratedCreditsComponent",
  props:{
    carbonFarmID: null
  },
  components: { 
    BTable, 
    BCol
  },
  data(){
    return{
        selectMode: 'single',     
        fieldsViewTotalGeneratedCredits: [
                { key: 'fromDate', label: 'Desde' },
                { key: 'toDate', label: 'Hasta' },
                { key: 'emissions', label: 'Emisiones' },
                { key: 'captures', label: 'Capturas' },
                { key: 'net', label: 'Neto' },
                { key: 'generatedCredits', label: 'Créditos generados' }
            ],
        fromDate: null,
        toDate: null,
        emissions: null,
        captures: null,
        net: null,
        generatedCredits: null,
        totalGeneratedCredits: [{}],
        }
    },
    created(){
        this.GetViewTotalGeneratedCreditsByCarbonFarmID();
    },

    methods:{
        GetViewTotalGeneratedCreditsByCarbonFarmID(){
            axios.get('/CarbonMeasure/GetViewTotalGeneratedCreditsByCarbonFarmID',  { params: { carbonFarmID:this.carbonFarmID}})
            .then (response =>{
                this.totalGeneratedCredits = response.data;
                console.log(this.totalGeneratedCredits)              
                if(this.totalGeneratedCredits.length > 0){
                    this.totalGeneratedCredits.forEach(function(item, index){
                        this.fromDate = item.totalGeneratedCredits.fromDate.substring(0,10);
                        this.toDate = item.totalGeneratedCredits.toDate.substring(0,10);
                        this.emissions = item.emissions;
                        this.captures = item.captures;
                        this.net = item.net;
                        this.generatedCredits = item.generatedCredits
                    });
                }
            })                     
        },
        onRowSelected(items) {
            this.userSelected = items[0];  
        }
  }
}

</script>

<style>
.table td{
    padding-right: 0ch;
}
</style>