<template>
    <div>
            <b-row>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage1Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage1Date)}}</strong></p>
                <p>1. Pre-Registro</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage2Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage2Date)}}</strong></p>
                <p>2. Comprobación de integridad de documentos</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage3Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage3Date)}}</strong></p>
                <p>3. En Cola</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage4Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage4Date)}}</strong></p>
                <p>4. Revisión Técnica 1</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage5Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage5Date)}}</strong></p>
                <p>5. Revisión Legal</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage6Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage6Date)}}</strong></p>
                <p>6. En Espera de Respuestas del Productor</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage7Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage7Date)}}</strong></p>
                <p>7. Revisíon Técnica 2</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage8Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage8Date)}}</strong></p>
                <p>8. Aviso Aceptación / Rechazo</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage9Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage9Date)}}</strong></p>
                <p>9. Fase Contractual</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage10Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage10Date)}}</strong></p>
                <p>10. Fase Monitoreo</p>
            </b-col>
            <b-col class="text-center">
                <div>
                <div :class="CheckTimeline(carbonFarmInformation.stage11Date)"></div>
                </div>
                <p><strong>{{TransformDate(carbonFarmInformation.stage11Date)}}</strong></p>
                <p>11. Retiro</p>
            </b-col>
            </b-row>
        
    </div>
</template>

<script>

import axios from '@axios'
import moment from 'moment'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
    BTable, BCol, BRow, BCardBody, BAlert, BCard
} from 'bootstrap-vue'

export default {
  name:"PhasesRowTimelineCarbonFarm",
  props:{
    carbonFarmInformation:{}
  },
  components: { 
    BTable, 
    BCol,
    BRow,
    BCard,
    BCardBody,
    BAlert,
    AppTimeline,
    AppTimelineItem
  },
  data(){
    return{
        
        }
    },

    methods:{
        TransformDate(date){
            console.log('Este es el dato... ' + date)
          if (date == null) {
            return "--/--/----"}
          else 
          {return moment(date).format('DD/MM/YYYY')}        
        },
        CheckTimeline(date) {
          if(date == null){
            return "item-unchecked mx-auto my-1"}
          else{
            return "item-checked mx-auto my-1"
          }
        }
  }
}

</script>
<style lang="scss">
.item-checked {
    background-color: #00BD56;
    border: #00BD56;
    border-radius: 50%;
    height: 16px;
    width: 16px
  }
  .item-unchecked {
    background-color: #D8D9DA;
    border: 1px solid #61677A;    
    border-radius: 50%;
    height: 16px;
    width: 16px
  }
</style>